import {Layout} from "../layout"
import React from "react";
import SimpleSlider from "../components/Slideshow"
import {makeStyles} from "@material-ui/styles";
import { Link } from "gatsby";
const useStyles = makeStyles({
    container : {
        width : "80%",
        margin : "auto",
        marginTop : "2rem",
        lineHeight : "1.5rem",
        "& h4" : {
            fontSize : "1.5rem",
            fontWeight : "400",
        }
    }, 
    header : {
        fontSize : "2rem"
    },
})
const About = () => {
    const classes = useStyles()
    return (
        <Layout>
            <SimpleSlider hideAboutUs={true}/>
            <div className={classes.container}>
                <h2 className={classes.header}>About Meyking</h2>
Meyking Enterprise Inc is a full-service packaging provider. We are dedicated to provide you with the number one solution to custom packaging. <br/><br/>
We help you, company small or big, to create custom packaging from ideas, concept, structural design, product proofing, manufacturing, quality control, logistics, and customs clearance as well as local delivery to your doorstep. We have offices both in Canada and China to manage our customer relationships, product sourcing, and production and offer a turn-key solution to you. Our product catalog can be viewed by industry or by product type. <br/><br/>
Please browse our website to see some representative photos of <Link to="/products">our products</Link> by category or by Industry to get a better understanding. <br/><br/>
In custom packaging, we understand that every project is unique and tailer to your need. We provide personalized services and creative approach to work with you closely to ensure your projects go in the right track and right time in the following steps: <br/>
<h4>
Better understanding your need
</h4>
Our first and foremost important thing to do in custom packaging is to better understand you and your need. This simple-looking or took-it-for-granted negligence by many people will make the project complicated and waste time and money, so at the beginning of any project, we need to discuss and fully understand your need, what style of packaging you want, what material to use, what kind of printing and finishing you prefer, where the packaging to use and/or where your products to sell and how you position your products in the market. That also involves your estimated order quantity, price point or budget, time to launch, and how you plan to co-pack or distribute your products. The more we know, the better we can suggest to you the best way to design and manufacture products for you at the minimal cost within the time you would expect.
<h4>
Design
</h4>
We have our in-house structural designer, and graphic designer to help you create the product template/die-line, artwork layout, and computer rendering to realize your concept to visuality before prototyping and proofing. We incorporate the material selection, production process and workmanship, print and finish into our design process to help you evaluate your project prior to spend more money to make sample or production.
<h4>
Prototyping
</h4>
We have an in-house sample master who does sample mock-ups by hand or uses a CNC machine for custom paper boxes and bags or uses a 3D printer or clay molding and modeling for plastic cases and boxes, product molded inserts and trays, or a CNC cutter to make corrugate E-commerce mailer and shipper boxes. This step will help us to review the packaging's overall structure, sizing, and style. It is critical for packaging needed to pack your product if it is size sensitive and also give you the opportunity to see how the packaging would look like before actual printing and actual proofing.
<h4>
Artwork and Proofing
</h4>
Ideally, the artwork is provided by the customer by using our provided template /die-line. However, the customer may only provide us a logo or other packaging artwork for us to do layout artwork for proofing or production. Our in-house designer helps to place the artwork or to review and ensure your artwork is good for printing. Based on the timing, budget, and expectation, we have different ways to do proofing. It could be a simple digital output to review the print artwork position, proofreading, and overall color effect, but also could be real press print and finishing as is on real mass production for color accuracy and visual aesthetical approval. The different proofing processes will occur different costs which need to be well communicated in the design process. If you have coordinated packaging to go with the packaging, please also advise us at the beginning so we can match.
<h4>
Manufacturing
</h4>
Our factory is in Guangzhou China with advanced machinery and equipment, and most importantly, our team of experienced workers at our factory knows how to make beautiful packaging for you. We do order schedule and planning, material procurement, line set up, production, and assembly based on your request ship date /delivery date.
<h4>
Quality Control
</h4>
Knowing that a “Good product is NOT made by a QC”, we have our full-time Quality Controller (QC) to monitor and check the production at each production step throughout the production process to ensure the production is up to standard per our requirement, and to spot, to identify potential issue it may happen and coordinate with the production team to rectify and ensure production is done correctly. Our QC also will conduct product testing to ensure they meet the functionality requirements and other requests from the customers.
<h4>
Logistics, warehousing & Distribution
</h4>
In the past 20+ years, Meyking has worked with many well-known logistics providers and forwarders to help move your order from our factory to your doorstep without any headache to you. Our experienced staff will take care all for you. Depending on the cost, time, and feasibility, we will offer you the best shipping options to you for your decision. This can be included in our price quoted or charge as a line item at the end. We can clear customs in China and also in USA /Canada and arrange delivery to you. We offer container shipping, palletized LCL shipment or without pallets, and courier at an enjoyable discounted rate.<br/><br/>

We also have set up warehousing and distribution network in Canada and USA to help you for storage and distribution orders as per your needs at competitive pricing.
<h4>

Product warranty and safety
</h4>
Working with us will be with peace of mind. With rich experience in product design, material selection, and manufacturing, we pride ourselves in the industry to provide you with the top quality and workmanship for all the packaging products. We warrant all products are meeting their intended function and required safety standards for consumers.
                </div>
        </Layout>
    );
};
export default About;
